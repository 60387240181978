<template>
    <section class="browse-section">
        <div class="container my-container">
            <div class="browse-content">
                <div class="row no-gutters">
                    <div class="col-sm-12">
                    <h4>Browse</h4>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Homeschooling</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Textbooks</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Workbooks</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Magazines</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Publications</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Music</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">School Supplies</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Others</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">GRADE LEVEL</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Preschool</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Kindergarten</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 1</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 2</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 3</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 4</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 5</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 6</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 7</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 8</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 9</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 10</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 11</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Grade 12</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">SUBJECT</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Bible</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Language Arts</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Reading</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Math</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Science</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">History</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Handwriting</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Literature</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Logic</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Spelling</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Writing</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Others</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Elective</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Arts & Humanities</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Music </label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Foreign Language</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Geography</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Character</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Health & Fitness</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Economics</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Coding & Computer Science</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Consumer Math</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Life Skills</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Others</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Curriculum</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Abeka</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">ACE PACEs</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">BJU Press</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Apologia</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Master Books</label>
                            </li>
                
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Test Prep</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">SAT</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">LSAT</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Praxis Core</label>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Spiritual Growth</p>
                        </div>
                        <div class="card-content">
                        <ul>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Bibles</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Bible Studies</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Devotions</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">Books</label>
                            </li>
                            <li class="category-item">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="label-data">eBooks</label>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3">
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Format</p>
                        </div>
                        <div class="card-content-dropdown">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">All</option>
                            <option value="WY">Hardcover</option>
                            <option value="WY">Paperback</option>
                            <option value="WY">Other Article</option>
                        </select>
                        </div>
                    </div>
                    <div class="card-container">
                        <div class="head-title">
                        <p class="category-label">Rating</p>
                        </div>
                        <div class="card-content-rating">
                        <form class="rating-widget">
                            <input type="checkbox" class="star-input" id="1"/>
                            <label class="star-input-label" for="1">1
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star orange"></i>
                            </label>
                            <input type="checkbox" class="star-input" id="2"/>
                            <label class="star-input-label" for="2">2
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star orange"></i>
                            </label>
                            <input type="checkbox" class="star-input" id="3"/>
                            <label class="star-input-label" for="3">3
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star orange"></i>
                            </label>
                            <input type="checkbox" class="star-input" id="4"/>
                            <label class="star-input-label" for="4">4
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star orange"></i>
                            </label>
                            <input type="checkbox" class="star-input" id="5"/>
                            <label class="star-input-label" for="5">5
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star orange"></i>
                            </label>
                            
                        </form>
                        <p class="star-count">0 Star</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row no-gutters filters-section">
                    <div class="col-md-3">
                    <div class="mb-3">
                        <input type="text" class="form-control" id="search"  placeholder="Search">
                    </div>
                    </div>
                    <div class="col-md-6"></div>
                    <div class="col-md-3">
                    <div class="mb-3 row">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Sort</label>
                        <div class="col-sm-10">
                        <select class="js-example-basic-single" name="zipcode" style="width: 100%;">
                            <option value="AL">4433</option>
                            <option value="WY">3331</option>
                        </select>
                        </div>
                    </div>           
                    </div>
                    <hr>
                </div>
                <div class="articles-section">
                    <ul>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like active"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow active"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    <li class="arcticle-book-item">
                        <div class="img-container">
                        <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                        </div>
                        <div class="book-info-container">
                        <p class="title-article">
                            365 Read-Aloud Bedtime Bible...
                        </p>
                        <p class="author">
                            Daniel Partner
                        </p>
                        <div class="rating">
                            <ul>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="fas fa-star"></i></li>
                            <li><i class="far fa-star"></i></li>
                            </ul>
                        </div>
                        </div>
                        <div class="hover-container">
                        <div class="btn-grp">
                            <button class="btn btn-add-bag"> 
                            <svg id="bag" xmlns="http://www.w3.org/2000/svg" width="14.733" height="19.192" viewBox="0 0 14.733 19.192">
                            <path id="Path_84" data-name="Path 84" d="M24.357,959.362a3.578,3.578,0,0,0-3.571,3.571v.893H18.778a.725.725,0,0,0-.669.614l-1.116,13.39a.7.7,0,0,0,.669.725h13.39a.7.7,0,0,0,.669-.725l-1.116-13.39a.694.694,0,0,0-.669-.614H27.928v-.893A3.578,3.578,0,0,0,24.357,959.362Zm0,1.339a2.212,2.212,0,0,1,2.232,2.232v.893H22.126v-.893A2.212,2.212,0,0,1,24.357,960.7ZM19.4,965.164h1.388v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h4.463v1.046a1.562,1.562,0,1,0,1.339,0v-1.046h1.388l1,12.051H18.395Zm2.057,2.232a.223.223,0,1,1-.223.223A.214.214,0,0,1,21.456,967.4Zm5.8,0a.223.223,0,1,1-.223.223A.214.214,0,0,1,27.259,967.4Z" transform="translate(-16.991 -959.362)" fill="#fff"/>
                            </svg> Add to Bag
                            </button>

                            <button class="btn btn-like"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.362" height="17.272" viewBox="0 0 19.362 17.272">
                                <g id="like" transform="translate(1059.167 -70.531)">
                                <g id="Group_124" data-name="Group 124" transform="translate(-1060 66.334)">
                                    <path id="Path_90" data-name="Path 90" d="M4.605,26.7H1.372a.534.534,0,0,0-.539.539v8.622a.534.534,0,0,0,.539.539H4.605a.533.533,0,0,0,.539-.539V27.244A.533.533,0,0,0,4.605,26.7Z" transform="translate(0 -15.517)" fill="#6c782a"/>
                                    <path id="Path_91" data-name="Path 91" d="M31.624,12.21a1.705,1.705,0,0,0-1.754-1.767H24.649A5.837,5.837,0,0,0,25.1,5.859,2.326,2.326,0,0,0,23.046,4.2H23.03a1.129,1.129,0,0,0-1.194,1.033C21.7,6.594,21.105,9,20.25,9.858a7.58,7.58,0,0,1-2.358,1.521c-.148.072-.309.151-.48.236,0,.037,0,.074,0,.112v8.534l.365.125a14.864,14.864,0,0,0,5.37,1.083h4.221A1.705,1.705,0,0,0,29.127,19.7a1.9,1.9,0,0,0-.157-.761,1.667,1.667,0,0,0,.968-.536,1.811,1.811,0,0,0,.438-1.2,1.9,1.9,0,0,0-.157-.759,1.705,1.705,0,0,0,1.406-1.738,1.826,1.826,0,0,0-.478-1.249A1.83,1.83,0,0,0,31.624,12.21Z" transform="translate(-11.429)" fill="#6c782a"/>
                                </g>
                                </g>
                            </svg>                                    
                            </button>
    
                            <button class="btn btn-view"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="13.153" viewBox="0 0 19 13.153">
                                <g id="view" transform="translate(-0.999 -5)">
                                <path id="Path_1512" data-name="Path 1512" d="M19.922,11.249A10.723,10.723,0,0,0,10.5,5a10.723,10.723,0,0,0-9.422,6.249.731.731,0,0,0,0,.658A10.726,10.726,0,0,0,10.5,18.153,10.723,10.723,0,0,0,19.922,11.9.731.731,0,0,0,19.922,11.249ZM10.5,16.692a9.211,9.211,0,0,1-7.945-5.115A9.211,9.211,0,0,1,10.5,6.461a9.211,9.211,0,0,1,7.945,5.115A9.211,9.211,0,0,1,10.5,16.692Z" fill="#6c782a"/>
                                <path id="Path_1513" data-name="Path 1513" d="M14,10a4,4,0,1,0,4,4A4,4,0,0,0,14,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,14,16Z" transform="translate(-3.501 -2.423)" fill="#6c782a"/>
                                </g>
                            </svg>                                                                   
                            </button>
    
                            <button class="btn btn-follow"> 
                            <svg id="follow" xmlns="http://www.w3.org/2000/svg" width="19" height="16.399" viewBox="0 0 19 16.399">
                                <path id="Path_1511" data-name="Path 1511" d="M93.635,166.352a4.781,4.781,0,0,0-4.885,4.8c0,5.775,6.625,10.1,9.5,11.6,2.708-1.5,9.5-5.822,9.5-11.6,0-4.928-6.742-6.906-9.5-1.846A5.247,5.247,0,0,0,93.635,166.352Z" transform="translate(-88.75 -166.352)" fill="#6c782a"/>
                            </svg>                                                                                                                                             
                            </button>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
                <hr>
                <div class="row no-gutters">
                    <div class="col-md-6">
                    <nav aria-label="Page navigation" class="pagination-member">
                        <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                            <i class="fas fa-chevron-left"></i>
                            </a>
                        </li>
                        <li class="page-item "><a class="page-link active" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                            <i class="fas fa-chevron-right"></i>
                            </a>
                        </li>
                        </ul>
                    </nav>
                    </div>
                    <div class="col-md-6">
                    <p class="item-count">Displaying items 1-15 of 12241</p>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
</template>